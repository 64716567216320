import React from 'react';
import './css/Profile.scss';
import Container from "../fragment/Container";
import Developer from '../fragment/Developer';

function Profile() {

    

    return (
        <Container>
            <Developer/>
        </Container>
    );
}

export default Profile;
